<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.width')"
                vid="width"
                rules="required|numeric"
              >
                <b-form-group
                  :label="$t('labels.width')"
                >
                  <b-form-input
                    v-model="form.width"
                    :placeholder="$t('labels.width')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.height')"
                vid="height"
                rules="required|numeric"
              >
                <b-form-group
                  :label="$t('labels.height')"
                >
                  <b-form-input
                    v-model="form.height"
                    :placeholder="$t('labels.height')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="type"
                :name="$t('type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('type')"
                >
                  <v-select
                    v-model="form.type"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name"
                    :options="types"
                    :reduce="item => item.id"
                    :placeholder="$t('type')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      types: [],
      form: {
        title: {
          en: null,
          ar: null,
        },
        width: null,
        height: null,
        type: null,
      },
    }
  },
  created() {
    this.getTypes()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getTypes() {
      this.axios.get('/options/adCategoriesType')
        .then(res => {
          this.types = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            width: data.width,
            height: data.height,
            type: data.type,
          }
        })
    },
  },

}
</script>
